import css from "../assets/css.png";
import git from "../assets/git.png";
import html from "../assets/html.png";
import javascript from "../assets/javascript.png";
import node from "../assets/node.png";
import react from "../assets/react.png";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import forfun from "../assets/forfun.jpg";
import amt from "../assets/amt.jpg";
import ms from '../assets/ms.jpg'
import jsm from '../assets/jsm.jpg'
const images = {
	css,
	git,
	html,
	javascript,
	node,
	react,
	redux,
	sass,
	forfun,
	amt,
	ms,
	jsm
};
export default images;
